<template>
  <div v-if="currentUser && load_module" id="learning-event">
    <div id="content-block">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <div class="learning-img-block">
              <img src="/images/learning-img.png" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-12">
            <div class="learning-content-block">
              <ul>
                <h1>Gear up for an interactive<br />learning event!</h1>
                <p>What you will need:</p>
                <li>
                  <img src="/images/browser-icon.svg" /> Internet browser
                  (Chrome Preferred)
                </li>
                <li>
                  <img src="/images/internet-icon.svg" /> Strong internet speeds
                </li>
                <li><img src="/images/camera-icon.svg" /> Camera/Mic</li>
                <button
                  @click="
                    $router.push({
                      name: 'meet',
                      query: {
                        event_code: $route.query.event_code,
                        user_code: $route.query.user_code,
                      },
                    })
                  "
                  class="btn btn-theme mt-2"
                  id="permission-btn"
                >
                  Enter the Event
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h4><center>You don't have permission.</center></h4>
  </div>
</template>

<script>
// import { Form, Field, ErrorMessage } from "vee-validate";
// import * as yup from "yup";

export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    event() {
      return this.$store.state.auth.event;
    },
  },
  data() {
    return {
      loading: false,
      message: "",
      load_module: false,
      // schema,
    };
  },
  created() {
    this.Loading = true;
    var event_data = {
      event_code: this.$route.query.event_code,
      user_code: this.$route.query.user_code,
      API_URL: this.API_URL,
    };
    this.$store.dispatch("auth/verifyEvent", event_data).then((r) => {
      if (r.user) {
        this.load_module = true;
      }
    });
  
  },
  methods: {
    toaster_msg(existWarning) {
      this.$toast.show(existWarning, {
        type: "error",
        position: "top-right",
        duration: 4242,
      });
    },
    getCurrentTime() {
      let date = new Date();
      let options = {
        timeZone: "UTC",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleTimeString("en-us", options);
    },
  },
};
</script>

<style scoped>
.custom-control-input {
  position: absolute !important;
  top: 0.25rem;
  left: -1.5rem !important;
  display: block;
  width: 1rem !important;
  height: 1rem !important;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
  border-radius: 0;
  opacity: 1 !important;
}
.has-search .form-control-feedback {
  margin-top: 8px;
}
.field-loginform-email .help-block,
.field-loginform-password .help-block {
  color: #e08a9e;
  text-align: center;
  margin-top: 15px;
  font-style: italic;
  font-weight: 400;
}
.error-feedback {
  color: red;
}
</style>
