import socket from "../../plugins/webSocket";
import AllService from "../../services/all-service";
export const chat = {
  namespaced: true,
  state: {
    messages: [],
  },
  actions: {
    //saving chat to database.
    saveChatInDb({ commit }, data) {
      return AllService.save_chat_in_db(data).then(
        (data) => {
          // if (data) commit("verifySuccess", data);
          return Promise.resolve(data);
        },
        (error) => {
          commit("commitFailure");
          return Promise.reject(error);
        }
      );
    },
    //send msg to socket .
    /* eslint-disable no-unused-vars */
    sendMsg({ state }, data) {
      socket.emit("message", data);
    },
    /* eslint-enable no-unused-vars */
    //receving  msg from socket .


    //receiving messages from socket

    receivedMsg({ state }) {
      socket.on("message:received", (data) => {
        console.log("receive" + data.room);
        state.messages = state.messages.concat(data);
        console.log(state.messages);
      });
    },
    //get all chats from database .
    getChats({ commit }, data) {
      return AllService.get_all_chats(data).then(
        (data) => {
          console.log(data.messages);
          if (data) commit("setAllChats", data.messages);
          return Promise.resolve(data);
        },
        (error) => {
          commit("commitFailure");
          return Promise.reject(error);
        }
      );
    },
   
  },
  mutations: {
    setAllChats(state, data) {
      state.messages = data;
    },
    commitFailure() {
      alert("something went wrong");
    },
  },
};
