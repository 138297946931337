<template>
  <header class="navbar navbar-header" v-if="currentUser">
    <div class="navbar-brand">
      <a href="/" class="le-logo"
        ><img src="/images/logo.svg" />

        <div class="logo-text">DISCUSSION<br /><span>GURU</span></div>
      </a>
    </div>
    <div class="navbar-right">
      <div class="dropdown dropdown-profile">
        <div class="date-time">
          <a
            class="handshake"
            href="http://localhost/le-team/backend/web/event/index"
            ><i class="fas fa-handshake"></i
          ></a>
          <div class="date-time" id="timer"></div>
        </div>
        <a
          href="javascript:void(0)"
          class="dropdown-link"
          data-toggle="dropdown"
          data-display="static"
        >
          <div class="avatar avatar-sm">
            <img :src="imageUrl" alt="" class="rounded-circle" />
          </div>
          <div class="avatar-profile">
            <h5>
              {{ currentUser.user_details.firstname
              }}<i class="fas fa-chevron-down"></i>
            </h5>
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right tx-13">
          <h6 class="tx-semibold mg-b-5">
            {{
              currentUser.user_details.firstname +
                " " +
                currentUser.user_details.lastname
            }}
          </h6>
          <p class="tx-12 tx-color-03"></p>

          <div class="dropdown-divider"></div>
          <i data-feather="log-out"></i
          ><span class="cursor-pointer" @click="logOut">Logout</span>
        </div>
        <!-- <div class="meet-logout"> -->
        <!-- <a href=""><img src="/images/logout.png" /></a>
          </div>

          <div class="dropdown-divider"></div>
          <i data-feather="log-out"></i><span>Logout</span> -->
      </div>
    </div>
  </header>
  <div id="display_loading_login" v-if="loadingStatus">
    <div class="preloader overlay_hide"></div>
  </div>
  <router-view />
</template>

<script>
export default {
  data() {
    return {
      imageUrl: null,
      loadingStatus: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    // computed: {
    //   ...mapState("loader", ["loading"]),
    // },
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
    },
  },
  created() {
    // setting up image url
    // this.imageUrl = `${this.currentUser.user_details.avatar_base_url}/${this.currentUser.user_details.avatar_path}`;
  },
};
</script>

<style>
@import "/app.css";
@import "/style.css";
@import "/font-awesome.css";
</style>
