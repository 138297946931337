import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "./plugins/font-awesome";
import Toaster from "@meforma/vue-toaster";
import socket from "./plugins/webSocket";
import Loading from "./components/loading.vue";
import SplitCarousel from "vue-split-carousel";

const app = createApp(App);
// app.use(VueSocketio, socketio("localhost:3000"));
// global api url
app.config.globalProperties.API_URL =
  "http://localhost/le-team/backend/web/api";
app.config.globalProperties.$soketio = socket;
app.component("Loading", Loading); // global registration - can be used anywhere

app
  .use(store)
  .use(router)
  .use(Toaster)
  .use(SplitCarousel)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
