import AllService from "../../services/all-service";
import socket from "../../plugins/webSocket";
// import axios from "axios";
export const lobby = {
  namespaced: true,
  state: {},
  actions: {
    //saving announcement in database
    saveTableTitle({ commit }, data) {
      return AllService.saveTableTitle(data).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          commit("commitFailure");
          return Promise.reject(error);
        }
      );
    },
    saveTimer({ commit }, data) {
      return AllService.saveTimer(data).then(
        (data) => {
          return Promise.resolve(data);
        },
        (error) => {
          commit("commitFailure");
          return Promise.reject(error);
        }
      );
    },

    /* eslint-disable no-unused-vars */
    sendTableTitle({ state }, data) {
      socket.emit("table_title", data);
    },
    /* eslint-enable no-unused-vars */

    //receiving annoucement from socket
    receivedTableTitle(data) {
      socket.on("table_title", data);
    },
  },
  mutations: {
    setAnnouncement(state, data) {
      state.announcements = data;
    },
    setReceiveAnnouncement(state, data) {
      state.announcements = state.announcements.concat(data);
    },
    commitFailure() {
      alert("something went wrong");
    },
  },
};
