import Vuex from "vuex";
import { auth } from "./module/auth";
import { announcement } from "./module/announcement";
import { chat } from "./module/chat";
import { lobby } from "./module/lobby";
const store = new Vuex.Store({
  modules: {
    auth,
    announcement,
    chat,
    lobby,
  },
});

export default store;

// const requireContext = require.context("./module", true, /.*\.js$/);

// const modules = requireContext
//   .keys()
//   .map((file) => {
//     return [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)];
//   })
//   .reduce((modules, [name, module]) => {
//     if (module.namespaced === undefined) {
//       module.namespaced = true;
//     }
//     return { ...modules, [name.replace("/", "_").toLowercase()]: module };
//   }, {});

// const store = new Vuex.Store({
//   modules,
// });

// export default store;
