import socketio from "socket.io-client";
const reconnectOptions = {
  reconnection: true, // Whether to reconnect automatically (default: true)
  reconnectionDelay: 2000, // How long to initially wait before attempting a new reconnection (default: 1000)
  reconnectionAttempts: 30, // Number of reconnection attempts before giving (default: Infinity)
  query: {
    socketId: "",
  },
};
let socket = socketio(
  "https://test.discussion.leadershipedge.team:3200",
  reconnectOptions
);
//local
console.log(socket);
export default socket;
