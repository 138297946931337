import axios from "axios";
import API_URL from "../plugins/api-url";

//can use only in setup()

// const API_URL = "http://localhost/le-team/backend/web/api";
export const customHeader = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class AuthService {
    //getting data user and event
  verify_event_with_userid(data) {
    return axios
      .get(
        API_URL +
          `/user/index?event_code=${data.event_code}&user_code=${data.user_code}`
      )
      .then((response) => {
        if (response.data.user) {
          localStorage.setItem("user_data", JSON.stringify(response.data.user));
          localStorage.setItem(
            "event_data",
            JSON.stringify(response.data.event)
          );
        }
        return response.data;
      });
  }

  // login(user) {
  //   return axios
  //     .post(API_URL + "signin", {
  //       username: user.username,
  //       password: user.password,
  //     })
  //     .then((response) => {
  //       if (response.data.accessToken) {
  //         localStorage.setItem("user", JSON.stringify(response.data));
  //       }

  //       return response.data;
  //     });
  // }

  // register(user) {
  //   return axios.post(API_URL + "signup", {
  //     username: user.username,
  //     email: user.email,
  //     password: user.password,
  //   });
  // }
}
export default new AuthService();
