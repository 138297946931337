<template>
  <div id="display_loading_login" v-if="loading">
    <div class="preloader overlay_hide"></div>
  </div>
</template>
<script>
export default {
  name: "Loader",
  data: () => ({
    loading: false,
  }),
};
</script>
