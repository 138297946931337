import AllService from "../../services/all-service";
import socket from "../../plugins/webSocket";
// import axios from "axios";
export const announcement = {
  namespaced: true,
  state: {
    announcements: [],
    loadingStatus: false,
  },
  actions: {
    //saving announcement in database
    sendAnnoucement({ commit }, data) {
      return AllService.send_annoucement(data).then(
        (data) => {
          console.log("store");
          // if (data) commit("verifySuccess", data);
          return Promise.resolve(data);
        },
        (error) => {
          commit("commitFailure");
          return Promise.reject(error);
        }
      );
    },

    //get all annoucement from database

    getAnnouncements({ commit }, data) {
      commit("loadingStatus", true);
      return AllService.get_annoucement(data).then(
        (data) => {
          console.log(data.announcements);
          if (data) commit("setAnnouncement", data.announcements);
          return Promise.resolve(data);
        },
        (error) => {
          commit("commitFailure");
          return Promise.reject(error);
        }
      );
    },

    //sending annoucement to socket

    /* eslint-disable no-unused-vars */
    submitAnnoucement({ state }, data) {
      socket.emit("announcement", data);
    },
    /* eslint-enable no-unused-vars */

    //receiving annoucement from socket
    receivedAnnoucement({ commit }) {
      socket.on("announcement:received", (data) => {
        if (data) commit("setReceiveAnnouncement", data);
        return Promise.resolve(data);
      });
    },
  },
  getters: {
    loadingStatus(state) {
      console.log("reached in getters");
      return state.loadingStatus;
    },
  },
  mutations: {
    setAnnouncement(state, data) {
      state.announcements = data;
    },
    setReceiveAnnouncement(state, data) {
      state.announcements = state.announcements.concat(data);
    },
    loadingStatus(state, status) {
      state.loadingStatus = status;
    },
    commitFailure() {
      alert("something went wrong");
    },
  },
};
