import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import main from "./pages/main.vue";
// import Register from "./components/Register.vue";
// lazy-loaded
const EventIndex = () => import("./pages/meet/index");
const video = () => import("./pages/meet/video");
// const BoardAdmin = () => import("./components/BoardAdmin.vue")
// const BoardModerator = () => import("./components/BoardModerator.vue")
// const BoardUser = () => import("./components/BoardUser.vue")

const routes = [
  {
    path: "/home",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/",
    component: main,
  },
  {
    path: "/video",
    component: video,
  },
  {
    path: "/meet/index",
    name: "meet",
    component: EventIndex,
  },
  // {
  //   path: "/profile",
  //   name: "profile",
  //   // lazy-loaded
  //   component: Profile,
  // },
  // {
  //   path: "/admin",
  //   name: "admin",
  //   // lazy-loaded
  //   component: BoardAdmin,
  // },
  // {
  //   path: "/mod",
  //   name: "moderator",
  //   // lazy-loaded
  //   component: BoardModerator,
  // },
  // {
  //   path: "/user",
  //   name: "user",
  //   // lazy-loaded
  //   component: BoardUser,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;
