import axios from "axios";
import API_URL from "../plugins/api-url";

export const customHeader = {
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
};

class GlobalService {
  //save annoucement in db api
  send_annoucement(data) {
    return axios
      .get(
        API_URL +
          `/user/announcement-submit?event_id=${data.event_id}&announcement=${data.announcement}&user_id=${data.user_id}`
      )
      .then((response) => {
        return response.data;
      });
  }
  //get all annoucement from db api

  get_annoucement(data) {
    return axios
      .get(API_URL + `/user/get-announcements?event_id=${data.event_id}`)
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }
  //get all chats or messages from db api

  get_all_chats(data) {
    return axios
      .get(API_URL + `/user/get-chats?event_id=${data.event_id}`)
      .then((response) => {
        console.log(response);
        return response.data;
      });
  }
  //save chats or messages in db api

  save_chat_in_db(data) {
    return axios
      .get(
        API_URL +
          `/user/save-chat-message?user_id=${data.userId}&message=${
            data.message
          }&room=${"default-" + data.room}`
      )
      .then((response) => {
        // if (response.data.user) {
        //   localStorage.setItem("user_data", JSON.stringify(response.data.user));
        // }
        return response.data;
      });
  }
  //save table name in db api

  saveTableTitle(data) {
    return axios
      .get(
        API_URL +
          `/user/save-table-title?table_seat_id=${data.id}&tableTitle=${data.table_title}`
      )
      .then((response) => {
        return response.data;
      });
  }
  saveTimer(data) {
    return axios
      .get(
        API_URL +
          `/user/save-table-timer?table_seat_id=${data.id}&tableTimer=${data.table_timer}`
      )
      .then((response) => {
        return response.data;
      });
  }
}
export default new GlobalService();
