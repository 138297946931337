import AuthService from "../../services/auth-service";
import socket from "../../plugins/webSocket";

const user = JSON.parse(localStorage.getItem("user_data"));
const event = JSON.parse(localStorage.getItem("event_data"));

export const auth = {
  namespaced: true,
  state: { user, event, lobbyUsers: [], tables: [], seats: [] },
  actions: {
    //verify event and data using event_code and user_code
    verifyEvent({ commit }, data) {
      return AuthService.verify_event_with_userid(data).then(
        (user_data) => {
          commit("verifySuccess", user_data);
          return Promise.resolve(user);
        },
        (error) => {
          commit("commitFailure");
          return Promise.reject(error);
        }
      );
    },
    //logout and removing local storage data
    logout() {
      localStorage.removeItem("user_data");
      localStorage.removeItem("event_data");
      location.replace(
        "https://test.discussion.leadershipedge.team/backend/web/sign-in/login"
      );
    },
    //get all connected lobby users
    allLobbyUsers({ state }) {
      socket.on("all_lobby_users", (data) => {
        state.lobbyUsers = data;
        console.log('startsdadadadadadadadadadadadadadadadadadadadadadadadadasdsaasdasdsdasadsdasdadas hare');
        console.log(data);
        console.log("all_lobby_users" + JSON.stringify(data));
      });
    },

    //joining new user on entering event
    /* eslint-enable no-unused-vars */
    joinUser({ state }, data) {
      console.log("213123213" + state, data);
      socket.emit("join_user", data);
    },
    /* eslint-enable no-unused-vars */

    //check user is already exist or not in socket room.
    userExist() {
      socket.on("user_exist", function(data) {
        if (data) {
          const existWarning =
            "Your session is already working in other tab/device.";
          alert(existWarning);
          console.log("user already exit");
        }
      });
    },
  },
  mutations: {
    verifySuccess(state, user_data) {
      console.log(user_data);
      state.user = user_data.user;
      state.event = user_data.event;
      state.seats = user_data.seats;
      state.tables = user_data.tables;
    },
    save_chat(state, data) {
      state.messages = data;
    },
    commitFailure() {
      alert("something went wrong");
    },
  },
};
